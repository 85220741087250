import React, { useState, useMemo, useEffect } from 'react';
import { styled } from '@mui/material/styles';
// import { utcToZonedTime } from 'date-fns-tz';
import moment from 'moment-timezone';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { format, addDays, startOfMonth, endOfMonth, isWithinInterval } from 'date-fns';
import TextField from '@mui/material/TextField';
import { LocalizationProvider, DateRangePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Typography } from '@mui/material';
import { dispatch } from 'redux/store';
import { getDirectorDashboard } from 'redux/slices/director-dashboard';
import { weekdays } from 'moment';
import Button from '@mui/material/Button';
import { getAllHomeworkList, resetState } from '../../../redux/slices/homework';
import HomeworkForm from '../../../components/_dashboard/teacher/HomeworkForm';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { Icon } from '@iconify/react';
import EditHomeworkForm from 'components/_dashboard/teacher/EditHomeworkForm';
import edit2Outline from '@iconify/icons-eva/edit-2-outline';
import { createInputGrade, getAllGradeList, getGradeList } from '../../../redux/slices/grade';
import { RootState, useDispatch, useSelector } from '../../../redux/store';
import { AddCircle } from '@mui/icons-material';
import HomeworkDeleteConfirmationDialog from '../../../components/_dashboard/teacher/HomeworkDeleteConfirmationDialog';

import {
  deleteHomeworkPoint,
  getAllStudentList,
  getAllStudentListSuccess,
  getGrade,
  getGradePoinData,
  getHomeworkDates,
  getStudentGradePoint,
  getStudentNameList
} from 'redux/slices/student';
import EditGradeForm from 'components/_dashboard/teacher/EditGradeForm';
import { ListQueryParams } from '../../../@types/table';
import { IconButton } from '@mui/material';
import { Box } from '@mui/material';
import { Root } from 'rehype-raw';
import DuplicateHomeeworkForm from 'components/_dashboard/teacher/DuplicateHomeworkform';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { createGrade } from '../../../redux/slices/grade';
import { startOfWeek, eachDayOfInterval } from 'date-fns';
// import moment from 'moment';
import { useParams } from 'react-router';
import { getAllClassList } from 'redux/slices/class';
import { createClass, getClassByID, updateClass } from '../../../redux/slices/class';
const StyledTableCell = styled(TableCell)(({ theme }) => ({}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));

type CalenderFormProps = {
  editId: number | null;
};

export default function DirectorDashboardList({ editId }: CalenderFormProps) {
  const [data, setData] = useState<any>({});

  const [name, setName] = useState<string[]>([]);

  const [displayDays, setDisplayDays] = useState({});
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    startOfMonth(new Date()),
    endOfMonth(new Date())
  ]);

  const [openHomeworkForm, setOpenHomeworkForm] = useState(false);
  const [openDuplicateForm, setOpenDuplicateForm] = useState(false);
  const [selectedHomeworkId, setSelectedHomeworkId] = useState<string>('');
  const [selectedDuplicateHomeworkId, setSelectedDuplicateHomeworkId] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState('');

  const [openDelConf, setOpenDelConf] = useState(false);
  const [rows, setRows] = useState<any>([]);

  const [calendarEvents, setCalendarEvents] = useState<any>([]);
  const [titleByDate, setTitlesByDate] = useState<any>([]);

  const [chapterByDate, setChapterByDate] = useState<any>([]);
  const { homeworkList } = useSelector((state: RootState) => state.homework);
  const { classList } = useSelector((state: RootState) => state.class);
  const { studentLists } = useSelector((state: RootState) => state.student);
  const [openGradeForms, setOpenGradeForms] = useState(false);
  const [selectedGradeId, setSelectedGradeId] = useState<number | null>(null);
  const [queryParams, setQueryParams] = useState<ListQueryParams>({
    page: 1,
    pageSize: 5
  });
  const { gradeList, gradeListMeta } = useSelector((state: RootState) => state.grade);
  const [openEditGradeForm, setOpenEditGradeForm] = useState(false);
  const [editCell, setEditCell] = useState({ rowIndex: null, dateIndex: null });
  const [newPoint, setNewPoint] = useState('');
  const [classId, setClassId] = useState<any | null>(null);
  const [classID, setClassID] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const {
    class: classObject,
    error: classError,
    response: classResponse
  } = useSelector((state: RootState) => state.class);
  const [editData, setEditData] = useState(classObject);

  useEffect(() => {
    if (editId !== null) {
      setIsLoading(true);

      dispatch(getClassByID(editId))
        .then((response: any) => {
          const classIdFromResponse = response?.data?.classId || editId;
          setClassID(classIdFromResponse);

          dispatch(getDirectorDashboard(classIdFromResponse))
            .then((dashboardResponse: any) => {
              setData(dashboardResponse.data[0] || {});
            })
            .finally(() => {
              setIsLoading(false);
            });
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setEditData(null);
    }
  }, [editId, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await dispatch(getAllHomeworkList(editId));
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchClassList = async () => {
      await dispatch(getAllClassList());
    };
    fetchClassList();
  }, [dispatch]);

  useEffect(() => {
    if (classList.length > 0) {
      const selectedClassId = classList[0]?.id || null;
      setClassId(selectedClassId);
    }
  }, [classList]);

  useEffect(() => {
    const events = homeworkList.map((h: any) => ({
      id: h.id,
      title: h.title,
      start: h.date,
      end: h.date,
      allDay: true,
      backgroundColor: '#3788d8',
      borderColor: '#3788d8'
    }));

    setCalendarEvents(events);

    const titlesByDate = homeworkList.reduce((acc: any, h: any) => {
      // const formattedDate = format(new Date(h.date), 'yyyy-MM-dd');
      const formattedDate = moment.utc(h.date).format('YYYY-MM-DD');
      if (!acc[formattedDate]) {
        acc[formattedDate] = [];
      }
      acc[formattedDate].push({ title: h.title, id: h.id });
      return acc;
    }, {});

    const chaptersByDate = homeworkList.reduce((acc: any, h: any) => {
      // const formattedDate = format(new Date(h.date), 'yyyy-MM-dd');
      const formattedDate = moment.utc(h.date).format('YYYY-MM-DD');
      if (!acc[formattedDate]) {
        acc[formattedDate] = [];
      }
      acc[formattedDate].push({ chapter: h.chapter, id: h.id });
      return acc;
    }, {});

    setTitlesByDate(titlesByDate);
    setChapterByDate(chaptersByDate);
  }, [homeworkList]);

  const handleCellClick = (rowIndex: any, dateIndex: any) => {
    setEditCell({ rowIndex, dateIndex });
  };

  const handleInputChange = (e: any) => {
    setNewPoint(e.target.value);
  };

  const handleInputBlur = async (rowIndex: any, dateIndex: any) => {
    if (newPoint) {
      const date = weekDates[dateIndex];
      const formattedDate = format(new Date(date), 'yyyy-MM-dd');

      const row = rows[rowIndex];

      const studentId = row.id;

      const homeworkDate = formattedDate;

      const homeworkDetails = titleByDate[formattedDate] || [];

      const homeworkId = homeworkDetails.length > 0 ? homeworkDetails[0].id : 'No Title';

      const pointData = {
        point: newPoint,
        student_id: studentId,
        homework_id: homeworkId
      };

      try {
        await dispatch(createInputGrade(pointData, editId));
        await dispatch(getGradePoinData(editId));

        setNewPoint(pointData.point);
        setEditCell({ rowIndex: null, dateIndex: null });
      } catch (error) {
        console.error('Error posting point data:', error);
      }
    }
  };

  const handleDateRangeChange = (newRange: [Date | null, Date | null]) => {
    setDateRange(newRange);
  };

  const getMonthDates = (start: Date, end: Date) => {
    const dates = [];
    for (let date = start; date <= end; date = addDays(date, 1)) {
      dates.push(format(date, 'yyyy-MM-dd'));
    }

    return dates;
  };

  const monthDates = useMemo(() => {
    const start = dateRange[0] || startOfMonth(new Date());
    const end = dateRange[1] || endOfMonth(new Date());
    return getMonthDates(start, end);
  }, [dateRange]);

  const filteredDates = useMemo(() => {
    return monthDates.filter((date) => {
      const dateObj = new Date(date);

      return isWithinInterval(dateObj, {
        start: dateRange[0] || startOfMonth(new Date()),
        end: dateRange[1] || endOfMonth(new Date())
      });
    });
  }, [dateRange, monthDates]);

  const handleOpenCreateForm = (date: any) => {
    setSelectedDate(date);

    setOpenHomeworkForm(true);
  };
  const handleEditHomework = (homeworkId: any) => {
    setSelectedHomeworkId(homeworkId);
    setOpenHomeworkForm(true);
  };
  const handleDuplicateHomework = (homeworkId: string) => {
    setSelectedDuplicateHomeworkId(homeworkId);
    setOpenDuplicateForm(true);
  };
  const handleHomeworkFormClosed = () => {
    setOpenHomeworkForm(false);
    setSelectedHomeworkId('');
    dispatch(resetState());
  };
  const handleDuplicateHomeworkForm = () => {
    setOpenDuplicateForm(false);
    setSelectedDuplicateHomeworkId('');
    dispatch(resetState());
  };
  const handleHomeworkFormProcessedSuccess = () => {
    dispatch(getAllHomeworkList(editId));
    dispatch(getGradePoinData(editId));
  };

  const handleDeleteHomework = async (homeworkId: string) => {
    setOpenHomeworkForm(false);
    setSelectedHomeworkId(homeworkId);
    setOpenDelConf(true);
    await dispatch(deleteHomeworkPoint());
    await dispatch(getGradePoinData(editId));
  };
  const handleDeletingCancelled = () => {
    setOpenDelConf(false);
    setSelectedHomeworkId('');
    dispatch(resetState());
  };

  const handleDeleted = () => {
    setOpenDelConf(false);
    setSelectedHomeworkId('');
    dispatch(getAllHomeworkList(editId));
    dispatch(getGradePoinData(editId));
  };
  const handleOpenCreateForms = () => {
    setOpenGradeForms(true);
  };
  const handleGradeFormClosed = () => {
    setOpenGradeForms(false);
    setSelectedGradeId(null);
    dispatch(resetState());
  };
  const handleGradeFormProcessedSuccess = () => {
    dispatch(getGradeList(queryParams));
  };
  const handleEditGradeFormClosed = () => {
    setOpenEditGradeForm(false);
    setSelectedGradeId(null);
    dispatch(resetState());
  };
  const handleEditRecord = (gradeId: any) => {
    setSelectedGradeId(gradeId);
    setOpenEditGradeForm(true);
  };

  useEffect(() => {
    dispatch(getGradePoinData(editId));
    const result = dispatch(getGradePoinData(editId));
  }, [dispatch]);

  // useEffect(() => {
  //   if (!Array.isArray(studentLists)) {
  //     return;
  //   }

  //   const formattedRows = studentLists.map((student: any) => {
  //     const pointsMap = Array.isArray(student.points)
  //       ? student.points.reduce((acc: any, pointData: any) => {
  //           if (pointData.date && pointData.point != null) {
  //             const date = format(new Date(pointData.date), 'yyyy-MM-dd');

  //             acc[date] = {
  //               point: pointData.point,
  //               title: pointData.title,
  //               id: pointData.id
  //             };
  //           }
  //           return acc;
  //         }, {})
  //       : {};

  //     return {
  //       fullName: student.fullName,
  //       id: student.id,
  //       points: pointsMap
  //     };
  //   });

  //   setRows(formattedRows);
  // }, [studentLists]);

  useEffect(() => {
    if (!Array.isArray(studentLists)) {
      return;
    }

    const formattedRows = studentLists.map((student: any) => {
      const pointsMap = Array.isArray(student.points)
        ? student.points.reduce((acc: any, pointData: any) => {
            if (pointData.date && pointData.point != null) {
              const adjustedDate = pointData.date;

              acc[adjustedDate] = {
                point: pointData.point,
                title: pointData.title,
                id: pointData.id
              };
            }
            return acc;
          }, {})
        : {};

      return {
        fullName: student.fullName,
        id: student.id,
        points: pointsMap
      };
    });

    const sortedRows = formattedRows.sort((a, b) => a.fullName.localeCompare(b.fullName));

    setRows(sortedRows);
  }, [studentLists]);

  const formatDate = (isoDateString: any) => {
    const date = new Date(isoDateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const [selectedWeekStart, setSelectedWeekStart] = useState(new Date());

  const getWeekDates = (startDate: any) => {
    const weekDates = [];

    const mondayDate = moment(startDate).startOf('week').add(1, 'days');

    for (let i = 0; i < 7; i++) {
      const date = moment(mondayDate).add(i, 'days');

      const formattedDate = date.format('YYYY-MM-DD');

      weekDates.push(formattedDate);
    }
    return weekDates;
  };

  const handlePreviousWeek = () => {
    const previousWeekStart = moment(selectedWeekStart).subtract(1, 'week');
    setSelectedWeekStart(previousWeekStart.toDate());
  };

  const handleNextWeek = () => {
    const nextWeekStart = moment(selectedWeekStart).add(1, 'week');
    setSelectedWeekStart(nextWeekStart.toDate());
  };

  const handleCurrentWeek = () => {
    const currentWeekStart = moment().startOf('week').add(1, 'days');
    setSelectedWeekStart(currentWeekStart.toDate());
  };

  const weekDates = getWeekDates(selectedWeekStart);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '20px 0',
          flexWrap: 'wrap',
          padding: '0 20px'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" style={{ marginRight: 20 }}>
            Select Weeks
          </Typography>
          <Button
            variant="contained"
            style={{ marginLeft: 'auto', height: 50, marginTop: 10 }}
            onClick={handlePreviousWeek}
          >
            Previous Week
          </Button>
          <Button
            variant="contained"
            style={{ marginLeft: 20, height: 50, marginTop: 10 }}
            onClick={handleCurrentWeek}
          >
            Current Week
          </Button>
          <Button
            variant="contained"
            style={{ marginLeft: 20, height: 50, marginTop: 10 }}
            onClick={handleNextWeek}
          >
            Next Week
          </Button>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Student Name</StyledTableCell>

              {weekDates.map((dateStr, index) => {
                // const date = new Date(dateStr);

                const date = moment.tz(dateStr, 'YYYY-MM-DD', 'UTC').toDate();

                const dayName = format(date, 'EEEE');

                const isDisplayed = data ? data[dayName.toLocaleLowerCase()] : false;

                const titles = homeworkList.map((h: any) => h.title);
                if (isDisplayed) {
                  const formattedDate = format(date, 'yyyy-MM-dd');

                  const titlesForDate = titleByDate[formattedDate] || [];

                  const chaptersForDate = chapterByDate[formattedDate] || [];

                  return (
                    <StyledTableCell key={index} align="right">
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start'
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span>{format(date, 'EEE MM/dd')}</span>
                          <AddCircle
                            onClick={() => handleOpenCreateForm(formattedDate)}
                            style={{ marginLeft: '8px', cursor: 'pointer' }}
                          />
                        </div>

                        {titlesForDate.length > 0 && chaptersForDate.length > 0 && (
                          <div style={{ marginTop: '4px' }}>
                            {titlesForDate.map((title: { title: any; id: any }, idx: number) => (
                              <Box key={idx} display="flex" alignItems="center" mb={1} mt={1}>
                                {chaptersForDate[idx] && (
                                  <Typography
                                    variant="body2"
                                    style={{
                                      backgroundColor: '#438FFE',
                                      color: 'white',
                                      padding: '2px 4px',
                                      borderRadius: '4px',
                                      fontWeight: 'bold',
                                      marginRight: '8px'
                                    }}
                                    onClick={() => handleEditHomework(title.id)}
                                  >
                                    {title.title} - Chapter {chaptersForDate[idx].chapter}
                                  </Typography>
                                )}

                                <Icon
                                  icon={edit2Outline}
                                  style={{
                                    marginLeft: '8px',
                                    cursor: 'pointer',
                                    height: 20,
                                    width: 20
                                  }}
                                  onClick={() => handleEditHomework(title.id)}
                                />
                                <ContentCopyIcon
                                  onClick={() => handleDuplicateHomework(title.id)}
                                  style={{ marginLeft: '8px', cursor: 'pointer' }}
                                  fontSize="small"
                                />
                              </Box>
                            ))}
                          </div>
                        )}

                        {titlesForDate.length === 0 && (
                          <Typography variant="body2" style={{ color: '#888' }}>
                            No homework
                          </Typography>
                        )}
                      </div>
                    </StyledTableCell>
                  );
                } else {
                  return null;
                }
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row: any, rowIndex: any) => (
              <StyledTableRow key={rowIndex}>
                <StyledTableCell component="th" scope="row">
                  {row.fullName}
                </StyledTableCell>
                {weekDates.map((date, dateIndex) => {
                  const dayName = format(new Date(date), 'EEEE');
                  const isDisplayed = data ? data[dayName.toLocaleLowerCase()] : false;

                  const getUserTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

                  // if (isDisplayed) {
                  //   // const formattedDate = format(new Date(date), 'yyyy-MM-dd');

                  //   // const pointData = row.points[formattedDate];
                  //   const getUserTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

                  //   const userTimeZone = getUserTimeZone();
                  //   console.log('UserTimezone', userTimeZone);

                  //   const utcDate = new Date(date);
                  //   console.log('utcDate', utcDate);
                  //   const formattedDate = utcDate.toLocaleDateString('en-CA', {
                  //     timeZone: userTimeZone,
                  //     year: 'numeric',
                  //     month: '2-digit',
                  //     day: '2-digit'
                  //   });
                  //   console.log('Formatted Date:', formattedDate);

                  //   console.log('FormattedDate', formattedDate);
                  //   const adjustedFormattedDate = formattedDate.split('/').join('-');
                  //   console.log(adjustedFormattedDate);
                  //   const pointData = row.points[adjustedFormattedDate];
                  //   console.log('PointData', pointData);
                  //   return (
                  //     <StyledTableCell
                  //       key={dateIndex}
                  //       onClick={() => handleCellClick(rowIndex, dateIndex)}
                  //     >
                  //       {editCell.rowIndex === rowIndex && editCell.dateIndex === dateIndex ? (
                  //         <input
                  //           type="text"
                  //           defaultValue={pointData?.point || null}
                  //           onChange={handleInputChange}
                  //           onBlur={() => handleInputBlur(rowIndex, dateIndex)}
                  //           autoFocus
                  //           style={{ maxWidth: 50 }}
                  //         />
                  //       ) : pointData ? (
                  //         <span style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                  //           <div
                  //             style={{
                  //               width: 50,
                  //               height: 30,
                  //               display: 'flex',
                  //               alignItems: 'center',
                  //               justifyContent: 'center',
                  //               border: '1px solid #000',
                  //               borderRadius: '5px'
                  //             }}
                  //           >
                  //             {pointData.point}
                  //           </div>
                  //         </span>
                  //       ) : (
                  //         '-'
                  //       )}
                  //     </StyledTableCell>
                  //   );
                  // } else {
                  //   return null;
                  // }
                  if (isDisplayed) {
                    const formattedDate = format(new Date(date), 'yyyy-MM-dd');

                    const pointData = row.points[formattedDate];

                    return (
                      <StyledTableCell
                        key={dateIndex}
                        onClick={() => handleCellClick(rowIndex, dateIndex)}
                      >
                        {editCell.rowIndex === rowIndex && editCell.dateIndex === dateIndex ? (
                          <input
                            type="text"
                            defaultValue={pointData?.point || ''}
                            onChange={handleInputChange}
                            onBlur={() => handleInputBlur(rowIndex, dateIndex)}
                            autoFocus
                            style={{ maxWidth: 50 }}
                          />
                        ) : pointData ? (
                          <span style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                            <div
                              style={{
                                width: 50,
                                height: 30,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '1px solid #000',
                                borderRadius: '5px'
                              }}
                            >
                              {pointData.point}
                            </div>
                          </span>
                        ) : (
                          '-'
                        )}
                      </StyledTableCell>
                    );
                  } else {
                    return null;
                  }
                })}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {openHomeworkForm && selectedHomeworkId !== '' ? (
        <EditHomeworkForm
          editId={+selectedHomeworkId}
          classId={editId}
          openForm={openHomeworkForm}
          formClosed={handleHomeworkFormClosed}
          processedSuccess={handleHomeworkFormProcessedSuccess}
          onDelete={() => handleDeleteHomework(selectedHomeworkId)}
        />
      ) : (
        <HomeworkForm
          openForm={openHomeworkForm}
          editId={editId}
          dates={selectedDate}
          formClosed={handleHomeworkFormClosed}
          processedSuccess={handleHomeworkFormProcessedSuccess}
        />
      )}
      {openDelConf && (
        <HomeworkDeleteConfirmationDialog
          openDialog={openDelConf}
          actionCancelled={handleDeletingCancelled}
          actionDeleted={handleDeleted}
          recordId={selectedHomeworkId}
        />
      )}
      {/* {openGradeForms && (
        <GradeForm
          openForm={openGradeForms}
          formClosed={handleGradeFormClosed}
          processedSuccess={handleGradeFormProcessedSuccess}
        />
      )} */}

      {openEditGradeForm && selectedGradeId && (
        <EditGradeForm
          editId={selectedGradeId}
          openForm={openEditGradeForm}
          formClosed={handleEditGradeFormClosed}
          processedSuccess={handleGradeFormProcessedSuccess}
        />
      )}
      {openDuplicateForm && selectedDuplicateHomeworkId && (
        <DuplicateHomeeworkForm
          classId={editId}
          editId={+selectedDuplicateHomeworkId}
          openForm={openDuplicateForm}
          formClosed={handleDuplicateHomeworkForm}
          processedSuccess={handleHomeworkFormProcessedSuccess}
        />
      )}
    </LocalizationProvider>
  );
}
